<template>
    <div v-if="message" class="message_desc truncate">
        <div v-if="chat.last_message.message_author" class="truncate flex items-center">
            <div v-if="chat.is_public" class="mr-1">
                {{ messageAuthor }}:
            </div>
            <span v-if="message.message_reply" class="truncate mr-1 blue_color">
                <i class="fi fi-rr-undo" />
            </span>
            <div v-if="messageImages.length" class="message_images">
                <div v-for="image in messageImages" :key="image.id" class="message_images__image">
                    <img :data-src="image.path" class="lazyload" :key="image.id" />
                </div>
            </div>
            <span v-if="!messageFiles && messageImages.length && !lastMessageReplace.length" class="truncate">
                {{ messageImagesCount }}
            </span>
            <span v-if="messageFiles" class="truncate">
                {{ messageFiles }}<span v-if="lastMessageReplace.length" class="mr-1">,</span>
            </span>
            <span class="truncate">
                {{ lastMessageReplace }}
            </span>
        </div>
        <span v-else v-html="chat.last_message.text" />
    </div>
</template>

<script>
import { declOfNum } from '../utils'
export default {
    props: {
        chat: {
            type: Object,
            required: true
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        message() {
            return this.chat.last_message
        },
        messageAuthor() {
            if(this.user) {
                if(this.user.id === this.message.message_author.id) {
                    return 'Вы'
                } else {
                    const n = this.message.message_author.full_name.split(' ')
                    return `${n[0]}${n[1] ? ' ' + n[1].charAt(0).toUpperCase() : ''}${n[2] ? '.' + n[2].charAt(0).toUpperCase() + '.' : ''}`
                }
            }
            return ''
        },
        messageImages() {
            if(this.message.attachments?.length) {
                const files = this.message.attachments.filter(f => f.is_image)
                if(files.length > 4) {
                    return files.splice(0, 4)
                }

                return files
            }

            return []
        },
        messageImagesCount() {
            if(this.message.attachments?.length) {
                const file = this.message.attachments.filter(f => f.is_image)
                if(file.length) {
                    return file.length + ' '
                        + declOfNum(file.length,
                            [this.$t('chat.photo'), this.$t('chat.photos'), this.$t('chat.photosGen')])
                }
                
                return null
            }
            
            return []
        },
        messageFiles() {
            if(this.message.attachments?.length) {
                const file = this.message.attachments.filter(f => !f.is_image)
                if(file.length) {
                    return file.length + ' '
                        + declOfNum(file.length,
                            [this.$t('chat.fileC'), this.$t('chat.fileGenC'), this.$t('chat.filesC')])
                }
            }
            
            return null
        },
        lastMessageReplace() {
            let mess = this.chat.last_message
            let res = this.chat.last_message.text.toString().replace(/[<]br[^>]*[>]/gi," ")

            // Share
            if(res.trim().length === 0 && mess.share){
                if(mess.share.type === "tasks.TaskModel" || !mess.share?.time_interval) 
                    res = `"${this.$t('chat.task2', { counter: mess.share.counter })}"` // "Задача #"
        
                if(mess.share.type === "comments.CommentModel" || mess.share.type === "comments") 
                    res = `"${this.$t('chat.comment2', { text: mess.share.text })}"` // "Комментарий: "
        
                if(mess.share.type === "meetings.PlannedMeetingModel") 
                    res = `"${this.$t('chat.meeting2', { name: mess.share.name })}"` // "Собрание: "
        
                if(mess.share.type === "workgroups.WorkgroupModel" || mess.share.type === "workgroups.WorkGroupModel") 
                    res = mess.share.is_project ? `"${this.$t('chat.project2', { name: mess.share.name })}"` : `"${this.$t('chat.team', { name: mess.share.name })}"` // "Проект: " / "Команда: "
        
                if(mess.share.type === "tasks.TaskSprintModel" || mess.share?.time_interval) 
                    res = `"${this.$t('chat.sprint2', { name: mess.share.name })}"` // "Спринт: "
        
                if(mess.share.type === "event_calendar.EventCalendarModel" || mess.share.type === "event") 
                    res = `"${this.$t('chat.event2', { name: mess.share.name })}"` // "Событие: "
        
                if(mess.share.type === "crm.GoodsOrderModel") 
                    res = `"${this.$t('chat.order2', { counter: mess.share.counter })}"` // "Заказ: "
        
                if(mess.share.type === "tickets.TicketModel") 
                    res = `"${this.$t('chat.ticket2', { name: mess.share.config_1c.name })}"` // "База: "
            }
            return res
        }
    }
}
</script>

<style lang="scss" scoped>
.message_images{
    display: flex;
    align-items: center;
    margin-right: 5px;
    &__image{
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 5px;
        background: rgba(115, 115, 115, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border2);
        &:not(:last-child){
            margin-right: 3px;
        }
        img{
            object-fit: cover;
            vertical-align: middle;
            -o-object-fit: cover;
            max-width: 100%;
            opacity: 0;
            transition: opacity 0.05s ease-in-out;
            &.lazyloaded{
                opacity: 1;
            }
        }
    }
}
</style>